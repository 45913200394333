<template>
  <v-card style="width: 100%" max-width="500" class="pa-4">
    <v-form>
      <v-card-title class="title font-weight-regular justify-center">
        <span>Войти в систему</span>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="this.$store.getters['auth/status'] == 'error'" color="red darken-1" dark>
          {{ $t(this.$store.getters["auth/error"]) }}
        </v-alert>
        <a-form-model v-model="data" :model="model" :errors="errors" @validate="validate($event)" />

        <p>Нет личного кабинета? <router-link :to="{ name: 'registration' }" exact>Зарегистрироваться</router-link></p>
      </v-card-text>

      <v-card-actions>
        <v-btn type="submit" color="primary" @click.prevent="submit()">
          {{ $t("Login") }}
        </v-btn>
      </v-card-actions>
    </v-form>
    <portal to="v-app">
      <show-info />
    </portal>
  </v-card>
</template>

<script>
import { validate, validators, fillForm } from "./../../components/mixings";

export default {
  mixins: [validate, validators, fillForm],
  data() {
    return {
      data: {},
      model: [
        {
          name: "login",
          title: "Логин",
          validator: ["req", "tel"],
          type: "string",
          mask: "7**********",
        },
        {
          name: "password",
          title: "Пароль",
          type: "password",
          validator: ["req"],
        },
      ],
      errors: {},
    };
  },
  mounted() {},
  methods: {
    async submit() {
      if ((await this.validateAll(this.data)) || this.data.login == "admin") {
        await this.login();
      }
    },
    async login() {
      try {
        await this.$root.login({
          login: this.data.login,
          password: this.data.password,
        });
        console.log("oook");
        this.$router.push("/");
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
